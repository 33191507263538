import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import 'styles/post.scss';

const PostBody = ({ post }) => (
  <>
    <div className="hero-image-wrapper">
      {post.primary_tag &&
        <>
          <script dangerouslySetInnerHTML={{ __html: '<!--googleoff: snippet-->' }} />
          <div className="d-flex blog-breadcrumb container">
            <a href="/">Blog</a>
            <span className="spacer">/</span>
            <Link id="category" to={`/categories/${post.primary_tag.slug}`}>{post.primary_tag.name}</Link>
          </div>
          <script dangerouslySetInnerHTML={{ __html: '<!--googleon: snippet-->' }} />
        </>
      }
      <div
        className="hero" style={{
          backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, .6),transparent), url('${post.feature_image}')`
        }}
      />
    </div>
    <article className="container content">
      <section className="post-full-content">
        <script dangerouslySetInnerHTML={{ __html: '<!--googleoff: all-->' }} />
        <span id="published-on" className="date">{post.published_at_pretty}</span>
        <script dangerouslySetInnerHTML={{ __html: '<!--googleon: all-->' }} />

        <h1 className="content-title">{post.title}</h1>
        <section
          className="content-body load-external-scripts"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </section>
    </article>
  </>
);

PostBody.propTypes = {
  post: PropTypes.shape({
    codeinjection_styles: PropTypes.object,
    title: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    tags: PropTypes.array,
    published_at_pretty: PropTypes.string,
    primary_tag: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  }).isRequired
};

export default PostBody;
